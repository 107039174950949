.MuiInputBase-input{
  font-size: 15px!important;
}
.loginFormOuterContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(120deg, #EF3125 0%, #AA0806 100%) 0%
    0% no-repeat padding-box;
  padding: 30px 0px;
  display: flex;
  align-items: center;
}
.loginFromContainer {
  width: 100%;
  max-width: 550px;
  margin: 0px auto;
  display: flex;
}
.loginFromContainer .loginContainerwhite {
  padding: 30px;
  border-radius: 30px;
  background: #fff;
}
.loginFromContainer .loginContainerwhite > div > .title {
text-align: center;
margin-top: 0px;
margin-bottom: 15px;
color: #1b34e7;
text-transform: uppercase;
font-size: 20px
}
.loginFromContainer .loginContainerwhite > div > .heading {
  width: 350px;
  margin: 0px auto 10px auto;
  font-size: 25px;
  font-weight: normal;
  color: #000;
  text-align: center;
}
.loginFromContainer .loginContainerwhite form {
  width: 350px;
  margin: 0px auto;
}
.loginFromContainer .loginContainerwhite form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: #000 !important;
  outline: none;
}
.loginFromContainer .loginContainerwhite form > div {
  width: 100%;
  margin-bottom: 30px;
}
.loginFromContainer .loginContainerwhite form .MuiFormHelperText-root{
  font-size: 12px;
  color: red;
}
.loginFromContainer .loginContainerwhite form > div.loginPassword .MuiInputAdornment-root button{
background: transparent;
}
.loginFromContainer .loginContainerwhite form .rememberpart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loginFromContainer
  .loginContainerwhite
  form
  .rememberpart
  label
  span.MuiFormControlLabel-label {
  color: #000;
  font-size: 16px;
}
.loginFromContainer
  .loginContainerwhite
  form
  .rememberpart
  > div:nth-child(1)
  .MuiFormControlLabel-root {
  margin-bottom: 0px;
  margin-left: -11px;
  margin-right: 0px;
}
.loginFromContainer .loginContainerwhite form .rememberpart > div:nth-child(1) {
  text-align: right;
}
.loginFromContainer .loginContainerwhite form .rememberpart a {
  display: inline-block;
  margin-right: 0px;
  margin-left: auto;
  text-decoration: underline;
  color: #EF3125;
  cursor: pointer;
  font-size: 16px;
  margin-left: auto;
}
.loginFromContainer .loginContainerwhite form .loginbtn {
  border-radius: 6px;
  color: #fff;
  background: #EF3125;
  outline: none;
}
.loginFromContainer .loginContainerwhite form .loginbtn:focus {
  outline: none;
}
.loginFromContainer .loginContainerwhite .createAccount {
  margin-bottom: 50px;
  margin-top: 30px;
  text-align: center;
}
.loginFromContainer .loginContainerwhite .createAccount span {
  margin-right: 3px;
}
.loginFromContainer .loginContainerwhite .createAccount a {
  color: #EF3125;
  cursor: pointer;
  font-size: 16px;
}
.loginFromContainer .loginContainerwhite .connectionError {
  margin-bottom: 0px;
  margin-top: -15px;
}
.loginFromContainer .loginContainerwhite .connectionError p {
  font-size: 14px;
  color: red;
  margin-bottom: 14px;
}

@media screen and (max-width: 1366px) {
  .loginFromContainer .loginContainerwhite .logo {
    margin-bottom: 20px;
  }
  .loginFromContainer .loginContainerwhite > div > h4 {
    margin-bottom: 20px;
    font-size: 22px;
  }
  .loginFromContainer .loginContainerwhite form > div {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}

/********************************************/
.loginFromContainer .loginContainerwhite .forgotMidSection {
  margin: 0px auto 20px auto;
  width: 350px;
}
.loginFromContainer .loginContainerwhite .forgotMidSection img {
  display: block;
  margin: 0px auto 30px auto;
  width: 150px;
  height: auto;
}
.loginFromContainer .loginContainerwhite .forgotMidSection span {
  display: block;
  color: #000;
  font-size: 16px;
}
.loginFromContainer .loginContainerwhite .forgotMidSection > h4 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #00a4dc;
}
.loginFromContainer .loginContainerwhite form button {
  background: #EF3125;
  margin-bottom: 20px;
}
.loginFromContainer
  .loginContainerwhite
  form
  > div.MuiFormControl-root
  .MuiInput-underline::after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.loginFromContainer
  .loginContainerwhite
  form
  > div.MuiFormControl-root
  .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.loginFromContainer
  .loginContainerwhite
  form
  > div.MuiFormControl-root
  .MuiInput-underline:focus::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.loginFromContainer .loginContainerwhite .backtologin a{
display: inline-block;
color: #EF3125;
margin-left: 5px;
font-size: 16px
}

.passcodeModal .emailAddress{
  font-size: 14px;
  font-weight: 600;
}

.passcodeModal ul{
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  align-items: center;
  padding-left: 10px;
}

.passcodeModal ul li{
  margin-right: 30px;
  margin-left: 10px;
}

.modalDialogPasscode .continueBtn{
  margin: 10px 20px;
  border: 1px solid #000;
  box-shadow: 2px 2px #000;
  background: #ffffff;
  color: #000;
}

.modalDialogPasscode .continueBtn:hover{
  margin: 10px 20px;
  border: 1px solid #000;
  background: #fff;
  box-shadow: 2px 2px #000;
  color: #000;
}