.cover-form-wrapper {
    padding: 25px 20px 0;
}
.cover-form-wrapper .block-left {
    float: left;
    width: 55%;
    margin-right: 6%;
}
.cover-form-wrapper .block-right {
    float: right;
    width: 39%;
    text-align: center;
}
.cover-form-wrapper .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.cover-form-wrapper .form-group label {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    min-width: 150px;
    margin-right: 15px;
}
.cover-form-wrapper .form-group input, .cover-form-wrapper .form-group select {
    display: block;
    padding: 8px 10px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    border: 2px solid #000000;
    outline: none;
    box-shadow: none;
}
.cover-form-wrapper .form-group .fileupload-Btn input {
    display: none;
}
.cover-form-wrapper .form-group .fileupload-Btn .flexBox {
    display: flex;
    align-items: center;
}
.cover-form-wrapper .form-group .fileupload-Btn .flexBox label {
    margin-right: 15px;
}
.cover-form-wrapper .form-group .fileupload-Btn .flexBox span {
    font-size: 15px;
    font-weight: 700;
}
.cover-form-wrapper .form-group .fileupload-Btn label {
    padding: 4px 20px;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 700;
    min-width: 1px;
    margin-right: 0;
    border: 2px solid #000000;
    box-shadow: 3px 3px #000;
    cursor: pointer;
}
.cover-form-wrapper h2 {
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 20px;
}
.cover-form-wrapper .imageslide-box {
    max-width: 220px;
    margin: 0 auto;
    padding: 10px 15px;
    border: 2px solid #9b9b9b;
}
.cover-form-wrapper .imageslide-box img {
    max-width: 100%;
}
.cover-form-wrapper .alice-carousel__dots {
    display: none;
}
.cover-form-wrapper .alice-carousel__prev-btn {
    padding: 0;
    position: absolute;
    left: -35px;
    top: 35%;
    text-align: left;
    z-index: 1;
}
.cover-form-wrapper .alice-carousel__next-btn {
    padding: 0;
    text-align: right;
    position: absolute;
    right: -35px;
    top: 35%;
    z-index: 1;
}
.cover-form-wrapper .alice-carousel__prev-btn p, .cover-form-wrapper .alice-carousel__next-btn p {
    padding: 0;
}
.cover-form-wrapper .alice-carousel__prev-btn [data-area]::after, .cover-form-wrapper .alice-carousel__next-btn [data-area]::after {
    content: '';
    width: 10px;
    height: 10px;
    font-size: 0;
    border-right: 2px solid #3E3E3E;
    border-bottom: 2px solid #3E3E3E;
    display: inline-block;
    position: relative;
    transform: rotate(135deg);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.cover-form-wrapper .alice-carousel__next-btn [data-area]::after {
    transform: rotate(-45deg);
}
.cover-form-wrapper .bottom-detail h4 {
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 5px;
}
.cover-form-wrapper .button-box {
    display: flex;
    justify-content: center;
}
.cover-form-wrapper .button-box .btn {
    display: inline-block;
    vertical-align: middle;
    padding: 5px 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    border: 2px solid #000000;
    box-shadow: 3px 3px #000;
    margin:5px 12px;
}
/* Image Card */
.img-card {
    width:300px;
    position: relative;
    border-radius: 5px;
    text-align: left;

    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    -o-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
}
.img-card.iCard-style1 .card-title {
    position: absolute;
    font-family: 'Open Sans', sans-serif;
    z-index: 1;
    top: 10px;
    left: 10px;
    font-size: 30px;
    color: #fff;
}
.card-content {
    padding: 15px;
}
/* This css is for normalizing styles. You can skip this. */
*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}




.new {
    padding: 50px;
}

.form-group-checkbox {
    display: block;
    /*margin-bottom: 15px;*/
    margin-top: 20px;
    padding-top: 15px;
}

.form-group-checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    /*display: none;*/
    cursor: pointer;
}

.form-group-checkbox label {
    position: relative;
    cursor: pointer;
    margin-left: 10px;
}

.form-group-checkbox label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    /*border: 2px solid #0079bf;*/
    /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);*/
    padding: 10px;
    /*display: inline-block;*/
    display: none;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.form-group-checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    display: none;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}