.coupon > h3{
    border: 2px solid;
    padding: 4px 0px 4px 10px;
    font-size: 20px !important;
}
.coupon .couponBox{
    margin: 6px 14px;
}
.coupon .couponButton{
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 26px;
}

.coupon .couponButton .deleteAllBtn{
    margin-right: 20px;
    border: 2px solid #000;
    font-weight: 600;   
    font-size: 14px;
    box-shadow: 2px 2px #000;
    background: #fff;
}

.coupon .couponButton .deleteAllBtn:hover{
    font-weight: 600;   
    font-size: 14px;
    background: #fff;
    color: #000;
}

.coupon .couponButton .addCouponsButton{
    margin-right: 20px;
    border: 2px solid #000;
    border-radius: 3px;
    box-shadow: 2px 2px #000;
}

.coupon .couponButton .addCouponsButton > button{
    border: none;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
}
.coupon .couponButton .addCouponsButton > button:hover{
    color: #000;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
}
.MuiTouchRipple-root{
    display: none
}
.coupon .couponTable {
    margin: 18px 10px;
    width: calc(100% - 2%);
    border: 2px solid;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
}
.coupon .couponTable tr {
    background: #ffffff;
}
.coupon .couponTable tr:first-child {
    background: #cccccc;
}
.coupon .couponTable tr:nth-child(even) {
    background: #eeeeee;
}
.coupon .couponTable td,th{
    border-right: 2px solid;
    padding: 10px 5px;
}

.coupon .couponTable .editIcon > svg{
    cursor: pointer;
}

.coupon .couponTable .deleteIcon > svg{
    cursor: pointer;
}

/***************************************addModalCouponModal css **************************/
.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm{
    max-width: 900px !important;
    margin: 15px ;
    max-height: calc(100% - 30px);
}
.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm  .MuiDialogTitle-root{
    border-bottom: 1px solid #ccc;
    padding: 15px !important
}
.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .addModalContentBox{
    margin: 0px -15px;
    height: 100%;
    max-height: calc(100vh - 124px);
    overflow-y: auto;
    padding: 0px 15px;
}
.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .addModalContentBox .couponForm >  .flexBox{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0px -10px;
}
.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .addModalContentBox .couponForm >  .flexBox .item{
width: calc(25% - 20px);
margin: 0px 10px 20px 10px;
}

.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .addModalContentBox .couponForm >  .flexBox .item .MuiFormControl-root {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
}

.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .addModalContentBox .couponForm >  .flexBox .discountAmountBox{
    display: flex;
    align-items: center;
}

.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .addModalContentBox .couponForm >  .flexBox .discountAmountBox .dollarSymbol{
    font-size: 18px;
    font-weight: 700;
    margin-top: 8px;
    display: inline-block;
}

.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .addModalContentBox .couponForm >  .flexBox .uploadItemBox{
    width: calc(50% - 20px);
}
.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .addModalContentBox .couponForm >  .flexBox .uploadItemBox label.chooseImage{
    width: 100%;
    display: block;
    color: #000;
    margin-bottom: 5px;
    line-height: normal;
    font-size: 16px;
    opacity: 1;
}
.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .actionBtnBox{
    text-align: center
}
.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .actionBtnBox button.saveBtn{
    border: 2px solid #000;
    border-radius: 3px;
    box-shadow: 2px 2px #000;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    padding: 10px 15px;
    min-width: 90px;
    line-height: normal;
    margin: 5px 0px;
}
.addModalCouponModal.modalDialog .MuiDialog-paperWidthSm .addModalContentBox .couponForm >  .flexBox .item.descriptionFieldBox .MuiFormControl-root textarea{
min-height: 100px;
max-height: 100px;
line-height: 1.45;
overflow-y: auto !important; 
}
.addModalContentBox .MuiFormHelperText-root{
    font-size: 12px !important;
    color: red !important;
}
.MuiFormControl-root{
    width: 100%!important;
}
.MuiInputBase-inputMultiline{
    height:60px!important;
    line-height: 1.4!important;
    overflow-y: auto!important;
}
/********************************************************************************/