header.MuiAppBar-colorPrimary {
  background-color: #fff !important;
  display: flex !important;
  height: auto;
  z-index: 1201;
}
header.MuiAppBar-positionFixed {
  height: auto;
  min-height: 60px
}
header.MuiAppBar-colorPrimary .subHeader{
display:flex;
align-items: center;
flex-wrap: wrap;
background: #f1f1f1;
padding: 15px;
} 
header.MuiAppBar-colorPrimary .subHeader .statement p{
  margin: 0px;
  white-space: normal;
  word-break: break-all;
  word-break: break-word;
  color: #000;
  font-weight: 600;
  font-size: 16px;
}
header.MuiAppBar-colorPrimary .subHeader .statement p span{
  display: inline-block;
  text-decoration: underline;
}
header.MuiAppBar-colorPrimary .subHeader .statement p span.new{
  color: #ef3125;
  font-weight: 600;
  text-decoration: none
}
header.MuiAppBar-colorPrimary .subHeader .actionFlexbox{
  display:flex;
  align-items: center;
  margin-left: auto;
}
header.MuiAppBar-colorPrimary .subHeader .actionFlexbox button{
  background: #000;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 15px;
  color: #fff;
  padding: 5px 10px;
} 
header.MuiAppBar-colorPrimary .subHeader .actionFlexbox button .MuiButton-label >  div{
  display: flex;
  align-items: center;

}
header.MuiAppBar-colorPrimary .subHeader .actionFlexbox button .MuiButton-label >  div svg{
  fill: #fff;
  width: 25px;
  height: auto;
}
header.MuiAppBar-colorPrimary .subHeader .actionFlexbox button .MuiButton-label >  div svg g{
  fill: #fff;
}
header.MuiAppBar-colorPrimary .subHeader .actionFlexbox button .MuiButton-label >  div .f-col{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: flex-start;
  line-height: normal;
}
header.MuiAppBar-colorPrimary .subHeader .actionFlexbox button .MuiButton-label >  div .f-col small{
  line-height: normal;
font-size: 12px;
}
header.MuiAppBar-colorPrimary .subHeader .actionFlexbox button .MuiButton-label >  div .f-col span{
  line-height: normal;
  font-size: 16px;
}
header.MuiAppBar-colorPrimary .subHeader .actionFlexbox button:last-child{
  margin-right: 0px;

}
header.MuiAppBar-colorPrimary  .subHeadBanner{
  background: #ef3125;
  padding: 5px 10px;
}
header.MuiAppBar-colorPrimary  .subHeadBanner img{
  max-height: 100px;
  margin-left: 100px;
}

.Sidebar .MuiDrawer-paper{
  top: 110px;
  height: calc(100% - 115px);
  width: 100%;
  max-width: 300px;
}
.Sidebar .MuiDrawer-paper .profileflexBox{
   display: flex;
   padding: 15px;
   height: auto;
   min-height: auto;
   justify-content: flex-start
 }
 .Sidebar .MuiDrawer-paper .profileflexBox .imgBox{
   width: 120px;
   height: 120px;
   margin-right: 15px;
   border: 1px solid #ccc;
   border-radius: 4px;
 }
 .Sidebar .MuiDrawer-paper .profileflexBox .imgBox > img{
   width:120px;
   height: auto;
   max-width: 120px;
 }
 .Sidebar .MuiDrawer-paper .profileflexBox  .data h5{
    font-size: 16px;
    color: #000;
    margin-top: 0px;
    margin-bottom: 10px;
    line-height: normal;
    font-weight: 600;
    word-break: break-all;
    word-break: break-word;
    white-space: normal;
  }
  .Sidebar .MuiDrawer-paper .profileflexBox  .data span{
    line-height: normal;
    font-size: 16px;
    color: #000;
    display: block;
    word-break: break-all;
    word-break: break-word;
    white-space: normal;
  }
  .Sidebar .MuiDrawer-paper .outerDivForLists .MuiList-root .MuiListItem-root .icons{
    display: inline-flex;
  }
  .Sidebar .MuiDrawer-paper .outerDivForLists .MuiList-root .MuiListItem-root .icons svg{
width: 30px;
height: auto;
  }
  .Sidebar .MuiDrawer-paper .outerDivForLists .MuiList-root .MuiListItem-root .icons img{
    width: 30px;
height: auto;
  }
  .Sidebar .MuiDrawer-paper .outerDivForLists .MuiList-root .MuiListItemText-root span{
    font-weight: 600;
    font-size: 16px;
    color: #000;
  }
.mainSection {
  /* margin-top: 60px; */
  padding: 15px 0px;
  /* background-color: #ffffff; */
  position: fixed;
  top: 60px;
  height: calc(100% - 60px);
  left: 0px;
  width: 100%;
  overflow-y: auto;
}
.Sidebar .sidebarColor .MuiListItemText-root > span{
 color: #ef3125 !important;
}
.drawerOpenClass{
  position: fixed;
  top: 110px;
  height: calc(100% - 115px);
  width: calc(100% - 22%);
  margin-left: 302px;
  overflow-y: auto;
  background: #fff;
}
main{
  padding: 0px 10px 0px 0px !important;
}
/********************FullScreenSection css *****************/

.FullScreenSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #f2f8fe;
  min-height: 100vh;
  align-items: flex-start;
  position: relative;
  max-width: calc(100% - 230px);
  margin: 0px auto;
}
.FullScreenSection::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc(100% + 230px);
  left: -115px;
  top: 0px;
  height: 100%;
  background: #f2f8fe;
  z-index: 0;
}
.FullScreenSection header {
  width: 100%;
  padding: 6px 15px;
}
.FullScreenSection .outerDivForLists {
  width: 250px;
  margin-right: 20px;
  margin-top: 75px;
  background: #fff;
  box-shadow: 2px 2px 6px #00000029;
  z-index: 1;
}
.FullScreenSection .outerDivForLists .MuiListItem-root:hover {
  background: #f2f8fe;
}
.FullScreenSection .mainSection {
  width: calc(100% - 270px);
  z-index: 1;
  position: relative;
  height: auto;
  top: 0px;
  margin-top: 60px;
}

@media screen and (max-width: 1499px) {
  .FullScreenSection {
    max-width: calc(100% - 50px);
  }
  .FullScreenSection::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: calc(100% + 50px);
    left: -25px;
    top: 0px;
    height: 100%;
    background: #f2f8fe;
    z-index: 0;
  }
}
/*********************************************************/
