.userManagement > h3{
    border: 2px solid;
    padding: 4px 0px 4px 10px;
    font-size: 20px !important;
}

.userManagement .userManagementButton{
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 26px;
}

.userManagement .userManagementButton .notificationUser{
    margin-right: 20px;
    border: 2px solid #000;
    font-weight: 600;   
    font-size: 14px;
    box-shadow: 2px 2px #000;
    background: #fff;
}

.userManagement .userManagementButton .notificationUser:hover{
    font-weight: 600;   
    font-size: 14px;
    background: #fff;
    color: #000;
}

.userManagement .userManagementButton .addUserButton{
    margin-right: 20px;
    border: 2px solid #000;
    border-radius: 3px;
    box-shadow: 2px 2px #000;
}

.userManagement .userManagementButton .addUserButton > button{
    border: none;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
}
.userManagement .userManagementButton .addUserButton > button:hover{
    color: #000;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
}
.MuiTouchRipple-root{
    display: none
}

.userManagement .userManagementButton .downloadBtn{
    width: 132px;
    height: 46px;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 600;
}

.userManagement .userManagementButton .downloadBtn:hover{
    background: #fafafa;
}

.userManagement .usertable {
    margin: 18px 10px;
    width: calc(100% - 2%);
    border: 2px solid;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
}
.userManagement .usertable tr {
    background: #ffffff;
}
.userManagement .usertable tr:first-child {
    background: #cccccc;
}
.userManagement .usertable tr:nth-child(even) {
    background: #eeeeee;
}
.userManagement .usertable td,th{
    border-right: 2px solid;
    padding: 10px 5px;
}

.userManagement .usertable .deleteIcon > svg{
    cursor: pointer;
}

/********************************userManageAddUserModal css ************************/
.userManageAddUserModal.modalDialog .MuiDialog-paperWidthSm{
margin: 15px;
max-height: calc(100% - 30px);
}

.userManageAddUserModal.modalDialog .MuiDialog-paperWidthSm .MuiDialogTitle-root h6{
padding-bottom: 10px;
border-bottom: 1px solid #ccc;
}
.userManageAddUserModal.modalDialog  .formComponent .addUserManagementForm{
display: flex;
align-items: baseline;
flex-wrap: wrap;
}
.userManageAddUserModal.modalDialog  .formComponent .addUserManagementForm{
    margin: 0px -10px;
}
.userManageAddUserModal.modalDialog  .formComponent .addUserManagementForm > div{
    margin: 0px 10px;
    width: calc(33.3% - 20px);
    margin-bottom: 20px;
}
.userManageAddUserModal.modalDialog  .formComponent .addUserManagementForm > div .MuiFormControl-root{
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
}
.userManageAddUserModal.modalDialog .MuiDialogActions-root{
    justify-content: center;
}

/*************************************************************************************/