.Modal {
  width: auto;
  overflow: hidden;
}

.Modal::-webkit-scrollbar {
  width: 0 !important;
}
.ModalTitle {
  color: #000;
  font-weight: bold;
}

.Modal .MuiSvgIcon-root{
  color: #000;
  font-size: 22px !important
}

.Modal {
  width: 100% !important;
}
.modalDialog .MuiDialog-paperWidthSm {
  max-width: 700px !important;
  width: 100%;
}

.modalDialog.loader .MuiDialog-paperWidthSm {
  height: 300px !important;
  width: 500px !important;
}
.modalDialog.loader .MuiDialogContent-root {
  min-height: 233px;
  position: relative;
}
.modalDialog.loader .css-42igfv {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 195px;
  justify-content: center;
}

/*********************/

.MuiDialogTitle-root {
  padding: 15px 15px 0px 15px !important;
}
.MuiDialogContent-root {
  padding: 15px !important;
  border: none !important;
}
.MuiDialogActions-root {
  padding: 10px 15px 15px 15px !important;
}
.MuiDialogActions-root button.FormCancel {
  background: #fff !important;
  border: 1px solid #000 !important;
  border-radius: 4px;
  color: #000 !important;
  min-width: 100px !important;
}

 .MuiDialogActions-root button.FormSave { 
  background: transparent ;
    box-shadow: 2px 2px #000;
    color: #000 ;
    border-radius: 0px;
    border: 1px solid #000;
    text-transform: none ;
    min-width: 70px;
    padding: 7px 15px;
    line-height: normal
 } 

/* .MuiDialogActions-root button:last-child {
  color: #000 !important;
  border-radius: 30px;
  background: #7bcc06 !important;
  min-width: 100px !important;
} */
/********************/

.formComponent .MuiFormHelperText-root {
  color: red !important;
}

.Modal .formComponent {
  max-height: calc(100vh - 142px);
  overflow-y: auto;
  overflow-x: hidden;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
}
/* .formComponent::-webkit-scrollbar {
  display: none;
} */

/******************datePicker**********************/

.MuiPickersBasePicker-containerLandscape {
  padding: 0px !important;
}
.MuiPickersCalendarHeader-switchHeader {
  margin-top: 0px !important;
  margin-bottom: 4px !important;
}
.MuiPickersCalendarHeader-iconButton {
  padding: 5px !important;
}

.MuiPickersCalendarHeader-dayLabel {
  width: 30px !important;
  margin: 0px !important;
}

.MuiPickersDay-day {
  width: 30px !important;
  height: 30px !important;
  margin: 0px !important;
}
.MuiPickersDay-day .MuiTypography-root {
  font-size: 12px !important;
}

.MuiPickersBasePicker-pickerView {
  justify-content: space-between !important;
  padding: 0px !important;
  min-width: 210px !important;
  min-height: 235px !important;
}
.MuiPickersCalendarHeader-transitionContainer .MuiTypography-alignCenter {
  position: relative !important;
  font-size: 14px !important;
}
.MuiPickersCalendar-transitionContainer {
  min-height: 180px !important;
  margin-top: 5px !important;
}
.MuiPickersDay-daySelected {
  background-color: #00a4dc !important;
}
.MuiPickersDay-daySelected:hover {
  background-color: #00a4dc !important;
}
