.profileSetting{
    margin: 20px;
}

.profileSetting > h3{
font-size: 22px;
font-weight: 700;
}

.profileSetting .profileGroup{
margin-left: 100px
}

.profileSetting .profileGroup .form-group{
    display: block;
    margin-bottom: 20px;
}

.profileSetting .profileGroup .form-group label{
    font-size: 16px;
    margin-right: 80px;
    font-weight: 600;
}

.profileSetting .profileGroup .form-group:nth-child(3) label{
    font-size: 16px;
    margin-right: 118px;
}

.profileSetting .profileGroup .form-group:nth-child(4) label{
    font-size: 16px;
    margin-right: 46px;
}

.profileSetting .profileGroup .form-group:nth-child(5) label{
    font-size: 16px;
    margin-right: 84px;
}

.profileSetting .profileGroup .form-group:nth-child(6) label{
    font-size: 16px;
    margin-right: 96px;
}

.profileSetting .profileGroup .form-group input{
    width: 300px;
    height: 32px;
}

.profileSetting .profileGroup .saveBtn{
border: 2px solid #000;
font-weight: 600;   
font-size: 14px;
box-shadow: 2px 2px #000;
background: #fff;
margin-top: 24px;
}

.profileSetting .profileGroup .saveBtn:hover{
    border: 2px solid #000;
    font-weight: 600;   
    font-size: 14px;
    box-shadow: 2px 2px #000;
    background: #fff;
    margin-top: 24px;
}