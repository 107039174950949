.manageLocation > h3{
    border: 2px solid;
    padding: 4px 0px 4px 10px;
    font-size: 20px !important;
}

.manageLocation .addLocation{
    text-align: right;
    margin-top: 32px;
}
.manageLocation .addLocation > button{
    border: 2px solid;
    margin-right: 38px;
    box-shadow: 3px 3px #000 !important;
    font-weight: 600;
    font-size: 16px;
    padding: 0px 6px !important
}
.manageLocation .addLocation > button:hover{
    border: 2px solid;
    margin-right: 38px;
    box-shadow: 3px 3px #000 !important;
    background: #ffffff;
    font-weight: 600;
    font-size: 16px;
    padding: 0px 6px !important
}
.manageLocation .table {
    margin: 18px 10px;
    width: calc(100% - 2%);
    border: 2px solid;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
}
.manageLocation table tr {
    background: #ffffff;
}
.manageLocation table tr:first-child {
    background: #cccccc;
}
.manageLocation table tr:nth-child(even) {
    background: #eeeeee;
}
.manageLocation td,th{
    border-right: 2px solid;
    padding: 10px 5px;
}
.manageLocation table td img {
    display: block;
    max-width: 80px;
    margin:0 auto;
    padding: 10px;
    text-align: center;
}

.manageLocation table .editIcon > svg{
    cursor: pointer;
}

.manageLocation table .deleteIcon > svg{
    cursor: pointer;
}


/**********************************************************/
.addManageLocation .addLocation{
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}
.addManageLocation .addLocation .addLocationBtn {
    display: flex;
    align-items: center;
}
.addManageLocation .addLocation .addLocationBtn span {
    display:inline-block;
    vertical-align: middle;
    margin-right: 6px;
}
.addManageLocation .addLocation .addLocationBtn svg {
    width: 40px;
    height: 35px;
    cursor: pointer;
}
.addManageLocation .inner-boxwrap {
    padding: 20px 60px;
}
.addManageLocation .removeLocationButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:5px 10px;
    font-size: 16px;
    font-weight: 700;
    border:2px solid #000000;
    margin-bottom: 15px;
}
.addManageLocation .removeLocationButton .removeButton {
    display: flex;
    align-items: center;
}
.addManageLocation .removeLocationButton .removeButton span {
    margin-right: 6px;
}
.addManageLocation .formBox {
    padding:20px 30px 0;
    max-width: 600px;
    margin: 0 auto
}
.addManageLocation .formBox .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    flex-wrap: wrap
}
 .addManageLocation .formBox .form-group .error{
     width: calc(100% - 215px);
     margin-left: auto;
     margin-top: 5px
 }
.addManageLocation .formBox .form-group label {
    font-size: 14px;
    font-weight: 700;
    min-width: 200px;
    margin-right: 15px;
}
.addManageLocation .formBox .form-group input {
    display: block;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 400;
    border: 2px solid #000000;
    outline: none;
    box-shadow: none;
    width: calc(100% - 215px);
    padding: 10px;
}
.addManageLocation .fileupload-Btn {
    position: relative;
    display: flex;
    align-items: flex-end;
}
.addManageLocation .form-group .fileupload-Btn input {
    display: none;
}
.addManageLocation .form-group .fileupload-Btn label {
    padding: 4px 20px;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 700;
    min-width: 1px;
    margin-right: 0;
    border: 2px solid #000000;
    box-shadow: 3px 3px #000;
    cursor: pointer;
}
.addManageLocation .form-group .fileupload-Btn .fileName{
    margin-left: 15px;
    display: inline-block;
   
}
.addManageLocation .saveBtn {
    padding: 4px 20px;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 700;
    border: 2px solid #000000;
    box-shadow: 3px 3px #000;
}

.addManageLocation .saveBtn:hover{
    background: #ffffff;
    padding: 4px 20px;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 700;
    border: 2px solid #000000;
    box-shadow: 3px 3px #000;
}
.addManageLocation .saveData{
    text-align: right;
    width: 50%;
}
.addManageLocation > h3{
    border: 2px solid;
    padding: 4px 0px 4px 10px;
    font-size: 20px !important;
}
/**********************************************************/