body {
  margin: 0;
  color: #000 !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Proxima Nova Rg" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiButton-root {
  text-transform: none !important;
  outline: none;
}
.MuiButton-root:focus {
  outline: none;
}
.MuiTab-root {
  text-transform: none !important;
}
@font-face {
  font-family: "Proxima Nova Rg";
  src: url("./assets/Proximafonts/ProximaNova-Regular.woff2") format("woff2"),
    url("./assets/Proximafonts/ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.MuiTypography-body1 {
  font-family: "Proxima Nova Rg" !important;
}

.MuiTypography-root {
  font-family: "Proxima Nova Rg" !important;
}
.MuiButtonBase-root {
  font-family: "Proxima Nova Rg" !important;
}
.MuiListItem-root {
  font-family: "Proxima Nova Rg" !important;
}
.MuiFormLabel-root {
  font-family: "Proxima Nova Rg" !important;
}
.MuiSelect-select {
  font-family: "Proxima Nova Rg" !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #00a4dc !important;
}

.MuiSwitch-thumb {
  color: #747474;
}
.MuiSwitch-track {
  background: #fff !important;
  border: 1px solid #707070;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #00a4dc !important;
}
/* .MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb{
  color: #00a4dc !important;
} */
.MuiDialogTitle-root button {
  outline: none;
}
.MuiDialogTitle-root button:focus {
  outline: none;
}

.MuiIconButton-root {
  outline: none;
}
.MuiIconButton-root:focus {
  outline: none;
}
.MuiPickersToolbar-toolbar {
  height: 70px !important;
}
.MuiPickersToolbar-toolbar .MuiButton-label h4 {
  font-size: 25px;
}
.MuiButton-label{
  font-size: 14px !important
}

.MuiInputLabel-formControl{
  font-size: 16px !important
}
.MuiPickersYear-yearSelected {
  margin: 5px 0;
  font-weight: 500;
  font-size: 18px;
}
.swal-button{
  background-color: #fff !important;
  color: #000 !important;
  border: 2px solid #000;
  box-shadow: 2px 2px #000;
}

.swal-button:hover{
  background-color: #fff !important;
  color: #000 !important;
  border: 2px solid #000;
  box-shadow: 2px 2px #000;
}

.MuiTypography-root{
  font-size: 14px !important;
}

.MuiTypography-h6{
  font-size: 20px !important
}