.viewNotification > h3{
    border: 2px solid;
    padding: 4px 0px 4px 10px;
    font-size: 20px !important;
}
.viewNotification .table {
    margin: 18px 10px;
    width: calc(100% - 2%);
    border: 2px solid;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
}
.viewNotification table tr {
    background: #ffffff;
}
.viewNotification table tr:first-child {
    background: #cccccc;
}
.viewNotification table tr:nth-child(even) {
    background: #eeeeee;
}
.viewNotification td,th{
    border-right: 2px solid;
    padding: 10px 5px;
}
.viewNotification table td img {
    display: block;
    max-width: 80px;
    margin:0 auto;
    padding: 10px;
    text-align: center;
}

.pushNotification{
    padding: 0 15px 30px;
}
.pushNotification h3{
    font-size: 20px;
    padding: 0 0 5px;
    border-bottom:2px solid #000 ;
    
}

.pushNotification .wrap{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 40px;
}
.pushNotification .wrap label{
    width: calc(16% - 20px);
    font-size: 15px;
    font-weight: 600;
}

.pushNotification .wrap select{
    width: calc(42% - 20px);
    margin-left: 20px;
    height: 40px;
    padding: 5px;
}
.pushNotification .wrap textarea{
    width: calc(42% - 20px);
    margin-left: 20px;
    height: 100px;
    padding: 5px;
}


.pushNotification .wrap select.equal{
    width: calc(28% - 20px);
    margin-left: 20px;
    height: 40px;
}

.pushNotification .wrap input[type="text"]{
    width: calc(28% - 20px);
    margin-left: 20px;
    height: 40px;
    padding: 5px;
    border: 0px;
    border-bottom: 1px solid #cccccc;
}

.pushNotification .tablecontent{
    width: 100%;
    margin-top: 40px;
}

.pushNotification .tablecontent .title{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
}

.pushNotification .tablecontent .title span{   
    font-size: 14px;
    font-weight: 500;
    margin-left: 15px;
    padding: 3px 8px;
    border-radius: 15px;
    color: #fff;
    background: rgb(255, 0, 0);
}

.pushNotification .tablecontent .table{
    width: 100%;
    margin-top: 10px;
}

.pushNotification .tablecontent .table td,
.pushNotification .tablecontent .table th {
   border-right: 0px;
   text-align: left;
   padding: 12px 10px;
   border-bottom: 1px solid #ccc;
   font-size: 16px;
}

.pushNotification .tablecontent .table tr:nth-child(even){
    background: #f1f1f1;
}

.pushNotification .mainwrapper{
    width: 100%;
    margin-top: 30px;
}
.pushNotification .mainwrapper h4{
    font-size: 16px;
}
.pushNotification .mainwrapper .wrap{
    margin-top: 20px;
    display: block;
}

.pushNotification .mainwrapper .wrap.date label{
    width: 100%;
    display: block;
}

.pushNotification .mainwrapper .wrap.date input{
    width: 15%;
    display: inline-block;
    margin: 15px 20px 0px 0px;
}

.pushNotification .radionotice {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pushNotification .radionotice .radio {
    display:block;
    width: 300px;
    border-right: 1px solid #d9d9d9;
    margin-right: 30px;
}

.pushNotification .radionotice .radio label:first-child {
    font-weight: 600;
    display: block;
    width:200px;
}

.pushNotification .radionotice .radio label {
    font-weight: 500;
}

.pushNotification .radionotice .infoNotice span{
    font-weight: 600;
}

.pushNotification .mainwrapper  h4{
    width: 100%;
    display: block;
    margin-bottom: 5px;
}

.pushNotification .mainwrapper .endDatefield{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.pushNotification .mainwrapper.borderBottom{
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
}

.pushNotification .mainwrapper .endDatefield > div{
    margin-top: 15px;
}
.pushNotification .mainwrapper .endDatefield input[type="text"]{
    margin-left: 15px;
    height: 40px;
    padding: 5px;
    border: 0px;
    border-bottom: 1px solid #cccccc;
}

.pushNotification input[type="text"]:focus{
    outline: 0;
}

.pushNotification .notificationButton{
    margin-top: 40px;
    border: 2px solid #000;
    font-weight: 600;
    display: inline-block;
    font-size: 14px;
    box-shadow: 2px 2px #000;
    background: #fff;
}