.activitySection{
    padding: 15px;
}
.activitySection .selectDropBox{
    margin-bottom: 20px;
}
.activitySection .selectDropBox select{
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px;
    background: #fff
}
.activitySection .customDateRange{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.activitySection .dateInner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #000;
}

.activitySection .dateInner .dateField{
    margin-left: 6px;
}

.activitySection .dateInner button{
    padding: 6px;
    border: none;
    background: none;
}

.activitySection .dateInner button::after{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.activitySection .customDateRange .dropDown{
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
    max-width: 460px;
    top: 49px;
    box-shadow: 0px 10px 20px 0px #ccc;
}
.activitySection .customDateRange .dropDown ul{
    float: left;
      width: 100%;
      list-style: none;
      padding-left: 0px; 
      border-bottom: 1px solid #ccc;
}
.activitySection .customDateRange .dropDown ul li{
    float: left;
    width: 100%;
    padding: 15px 10px 5px 10px;
    border-left: 3px solid transparent;
    cursor: pointer;
}
.activitySection .customDateRange .dropDown ul li label{
    float: left;
    cursor: pointer;
}    
.activitySection .customDateRange .dropDown ul li .rdrDefinedRangesWrapper{
    display: none
}
.activitySection .customDateRange .dropDown ul li .applyBtn{
        margin-left: 10px;
        background: #FF6D00; 
}
.activitySection .customDateRange .dropDown ul li .applyBtn:hover{
    background: #FF6D00;   
}

.activitySection .customDateRange .downloadIcon{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.activitySection .customDateRange .downloadIcon .downloadExcel{
    cursor: pointer;
}
.activitySection .customDateRange .downloadIcon svg{
    width: 20px;
    height: auto;
    margin-right: 10px;
}